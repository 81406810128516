<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
    />
    <v-card
      flat
      outlined
      :dark="$store.getters.getColorPalette().isDark"
      :style="{
        border:
          '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode,
        'background-color':
          $store.getters.getColorPalette().background2ColorCode,
      }"
      class="pa-2"
    >
      <v-simple-table
        fixed-header
        dense
        :style="{
          'background-color':
            $store.getters.getColorPalette().backgroundColorCode,
        }"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="(item, index) in tableData.headers"
                class="text-left"
                :key="item"
                :class="$store.getters.getColorPalette().foregroundColorName"
                :color="$store.getters.getColorPalette().accentCode"
              >
                <strong>{{ item }}</strong>
                <span>
                  <v-btn small icon @click="editHeader(index)" color="blue">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn small icon color="red">
                    <DeleteConfirmation
                      v-on:confirm="deleteHeader(index)"
                      title="Delete Confirmation"
                      description="Are you sure you want to delete this?"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </DeleteConfirmation>
                  </v-btn>
                </span>
              </th>
              <th
                :class="
                  'text-left  text-subtitle-1 text-capitalize ' +
                  $store.getters.getColorPalette().foregroundColorName
                "
              >
                <v-btn
                  small
                  icon
                  @click="editHeader(tableData.headers.length)"
                  :color="$store.getters.getColorPalette().accentCode"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </th>
              <th
                :class="
                  'text-right text-subtitle-1 text-capitalize ' +
                  $store.getters.getColorPalette().foregroundColorName
                "
              >
                Actions
              </th>
            </tr>
          </thead>
          <v-divider></v-divider>
          <tbody
            :style="{
              'background-color':
                $store.getters.getColorPalette().backgroundColorCode,
            }"
            v-if="!loading && tableData"
          >
            <tr
              v-for="(item, itemIndex) in tableData.rows"
              :key="itemIndex"
              :style="{
                'background-color':
                  $store.getters.getColorPalette().background1ColorCode,
              }"
            >
              <td class="text-left" v-for="(x, index) in item" :key="index">
                {{ x.type === "static" ? x.value : x.value.type }}
                <!-- <v-btn small   icon @click="editRowItem(itemIndex,index)" color="blue" >
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn> -->
                <v-btn
                  small
                  icon
                  :color="$store.getters.getColorPalette().deletebtnColor"
                >
                  <DeleteConfirmation
                    v-on:confirm="deleteRowItem(itemIndex, index)"
                    title="Delete Confirmation"
                    description="Are you sure you want to delete this?"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </DeleteConfirmation>
                </v-btn>
              </td>
              <td class="text-left text-capitalize">
                <v-btn
                  small
                  @click="editRowItem(itemIndex, item.length)"
                  icon
                  color="green"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td class="text-right text-capitalize">
                <v-btn
                  icon
                  :color="$store.getters.getColorPalette().deletebtnColor"
                >
                  <DeleteConfirmation
                    v-on:confirm="deleteRow(itemIndex)"
                    title="Delete Confirmation"
                    description="Are you sure you want to delete this?"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </DeleteConfirmation>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>
                <v-btn
                  :dark="$store.getters.getColorPalette().isDark"
                  @click="addRow"
                  small
                  text
                  outlined
                  >Add Row
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <GetTextModal
      :show="headerEdit"
      v-on:close="headerEdit = false"
      v-on:value="updateHeader"
    />
    <GetChecklistFieldModal
      :show="rowEdit"
      v-on:close="rowEdit = false"
      v-on:value="updateRowItem"
    />
    <v-row class="mt-1">
      <v-col align="right">
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>
        <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
          @click="submit()"
          >Add</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import GetTextModal from "@/components/modals/GetTextModal";
import GetChecklistFieldModal from "@/components/modals/checklist/GetChecklistFieldModal.vue";
export default {
  name: "ChecklistTableForm",
  props: ["table_json"],
  components: {
    InfoAlert,
    DeleteConfirmation,
    GetTextModal,
    GetChecklistFieldModal,
  },
  data() {
    return {
      info: "",
      showDismissibleAlert: false,
      loading: false,
      headerEdit: false,
      headerIndex: null,
      rowIndex: null,
      rowEdit: false,
      columnData: null,
      tableData: {
        headers: [],
        rows: [],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // Headers
    init() {
      if (
        this.table_json &&
        Object.keys(this.table_json) &&
        Object.keys(this.table_json).length > 0
      ) {
        this.tableData = this.table_json;
      } else {
        this.tableData = {
          headers: [],
          rows: [],
        };
      }
    },
    editHeader(index) {
      this.headerIndex = index;
      this.headerEdit = true;
    },
    updateHeader(value) {
      let headers = this.tableData["headers"].slice();
      headers[this.headerIndex] = value;
      this.tableData["headers"] = headers;
    },
    deleteHeader(index) {
      this.tableData.headers.splice(index, 1);
    },

    // Rows
    addRow() {
      this.tableData.rows.push([]);
    },
    deleteRow(index) {
      this.tableData.rows.splice(index, 1);
    },

    // Row Item
    editRowItem(itemIndex, index) {
      this.rowIndex = [itemIndex, index];
      this.rowEdit = true;
    },
    updateRowItem(value) {
      let rows = this.tableData["rows"][this.rowIndex[0]].slice();
      rows[this.rowIndex[1]] = value;
      this.tableData["rows"][this.rowIndex[0]] = rows;
    },
    deleteRowItem(itemIndex, index) {
      this.tableData["rows"][itemIndex].splice(index, 1);
    },
    submit() {
      this.$emit("value", this.tableData);
    },
  },
  watch: {
    table_json: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
