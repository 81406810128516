<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-skeleton-loader v-if="loading" class="my-5" align="center">
            <h1>
                Loading please wait...
            </h1>
        </v-skeleton-loader>        
        <v-row no-gutters>
            <v-col v-if="!loading">
                <ImageComponent
                    :conversionType="'blob'"
                    @imageData="handleTextRecognision"
                    :fieldData="{ key: 'user_image' }"
                />
            </v-col>    
        </v-row>
    </v-container>
</template>
<script>
import ImageComponent from './ImageComponent.vue';
import InfoAlert from '@/components/InfoAlert.vue'
import axios from 'axios';
export default {
    name: "OCRComponent",
    props:["fieldData"],
    components: {
        ImageComponent,
        InfoAlert
    },
    data() {
        return {
            info: '',
            showDismissibleAlert: false,
            loading: false,
            textResult: null,
        };
    },
    methods: {
        handleTextRecognision(returnData) {
            let finalData = { ...this.fieldData }
            this.loading=true
            const formData = new FormData();
            formData.append('file', returnData);
            // console.log(returnData,"returnData");
            axios.post(this.$store.state.api + "extractTextFromImage",formData, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    const extractedData = response.data.data;
                    const blocks = extractedData.Blocks || [];
                    // Extract text from the blocks
                    this.textResult = blocks
                        .filter(block => block.BlockType === 'LINE') // Focus on 'LINE' blocks
                        .map(block => block.Text) // Extract text from the blocks
                        .join('\n'); // Join lines with newlines for display
                    // console.log(this.textResult)
                    // this.$emit("imageToTextResult", this.textResult);
                    finalData["ocrData"]=this.textResult
                    finalData['blocks']=blocks
                    this.$emit("ocrData",finalData)
                    console.log(this.textResult)
                    this.loading = false
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                    this.loading = false
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err);
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        }
    }
}
</script>