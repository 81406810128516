<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-row>
            <v-skeleton-loader v-if="loading"></v-skeleton-loader>
            <v-col v-if="!loading">
                <ImageUploadComponent
                    @imageUploaded="handleImageUploadConversion"
                    :fieldData="{ key: 'user_image' }"
                />
            </v-col>    
        </v-row>
        <v-row>
            <v-col align="center">
                <h1>Or</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3>Capture Image</h3>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-skeleton-loader v-if="loading"></v-skeleton-loader>
            <v-col v-if="!loading">
                <ImageCapturingComponent
                    @imageCaptured="handleImageCaptureConversion"
                    :fieldData="{ key: 'user_image' }"
                />
            </v-col>    
        </v-row>
    </v-container>
</template>
<script>
import ImageCapturingComponent from './ImageCapturingComponent.vue';
import ImageUploadComponent from './ImageUploadComponent.vue';
import InfoAlert from '@/components/InfoAlert.vue'
export default {
    name: "OCRComponent",
    props:["conversionType"],
    components: {
        ImageCapturingComponent,
        ImageUploadComponent,
        InfoAlert
    },
    data() {
        return {
            info: '',
            showDismissibleAlert: false,
            loading: false,
            textResult: null,
        };
    },
    methods: {
        async blobToBase64(blob) {
            if (blob) {      
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            }
            return null
        },
        base64ToBlobConversion(image) {
            if (image && image.imageData) {                
                let base64Data = image.imageData
                const parts = base64Data.split(';base64,');
                if (parts.length !== 2) {
                    throw new Error('Invalid Base64 format');
                }
                const mimeType = parts[0].split(':')[1];
                const byteCharacters = Buffer.from(parts[1], 'base64');
                return(new Blob([byteCharacters], { type: mimeType }));
            }
            return null
        },
        async handleImageUploadConversion(recievedImageData) {
            try {
                if (this.conversionType === 'blob') {
                    this.emitData(recievedImageData)
                } else {
                    const base64String = await this.blobToBase64(recievedImageData);
                    this.emitData(base64String)
                }
            } catch (error) {
                console.error("Error converting Blob to Base64:", error);
            }
        },
        handleImageCaptureConversion(recievedImageData) {
            if (this.conversionType === 'blob') {
                const blob = this.base64ToBlobConversion(recievedImageData)
                this.emitData(blob)
            } else {
                this.emitData(recievedImageData.imageData)
            }
        },
        emitData(returnData) {
            this.$emit("imageData", returnData);
        }
    }
}
</script>