<template>
    <v-dialog v-model="show" max-width="500">
        <v-form ref="groupForm" v-model="valid">
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-card-text>
                    <v-select
                        v-model="type"
                        :items="typeOptions"
                        :label="$store.getters.getTextMap().type"
                        outlined
                        item-text="label"
                        item-value="value"
                        required
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :rules="objectRules"
                    ></v-select>
                    <v-select 
                        v-if="type==='input'"
                        placeholder="Field Type" 
                        v-model="field_type"
                        :rules="objectRules" 
                        :items="fieldTypeOptions" 
                        item-text="label" 
                        item-value="value"
                        outlined dense
                    ></v-select>
                    <v-text-field
                        v-else
                        v-model="text"
                        :counter="500"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().value"
                        required
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">
                        {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                    <v-btn :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">
                        {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
export default {
    name:'GetChecklistFieldModal',
    props:['show','name'],
    data(){
        return {
            type:'static',
            text:null,
            valid: false,
            field_type:null,
            typeOptions: [
                {label:"Static",value:"static"},
                {label:"Input",value:"input"}
            ],
            fieldTypeOptions: [
                {
                    label: 'Text',
                    value: 'text'
                },
                {
                    label: "Check",
                    value: "check"
                },
                // {
                //     label: "Image",
                //     value: 'image'
                // },
                {
                    label:"QR Code",
                    value:"qrtext"
                },
                {
                    label:"Image to Text Conversion",
                    value:"ocr"
                }
            ],
            nameRules: [
                v => !!v || 'required',
                v => /\S+/.test(v) || 'Text only',
                v => (v && v.length <= 500 && v.length > 0) || 'Name must be less than 501 characters',
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    mounted(){
      if(this.name){
        this.text=this.name
      }
    },
    methods:{
        onSubmit(){
            this.$refs.groupForm.validate()
            if (this.valid) {
                let returnValue={}
                if (this.type === 'static') {
                    returnValue["type"]=this.type
                    returnValue["value"]=this.text
                } else {
                    returnValue["type"] = this.type
                    let temp = {}
                    switch (this.field_type) {
                        case "text":
                            temp["type"] = this.field_type
                            temp["data"] = null
                            break;
                        case "check":
                            temp["type"] = this.field_type
                            temp["data"] = false
                            break;
                        case "image":
                            temp["type"] = this.field_type
                            temp["data"] = false
                            break;
                        case "qrtext":
                            temp["type"] = this.field_type
                            temp["data"] = null
                            break;
                        case "ocr":
                            temp["type"] = this.field_type
                            temp["data"] = null
                            break;
                    }
                    returnValue["value"] = temp
                }
                this.$emit('value',returnValue)
                this.$emit('close')
            }
        },
        onReset(){
            this.$emit('close')
        }
    },
    watch:{
        name:{
            handler(){
                this.text=this.name
            },
            deep:true
        }
    }
}
</script>